var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"6"}},[_c('div',[(_vm.subject)?_c('h3',{staticClass:"mx-2 mt-2"},[_vm._v(" "+_vm._s(_vm.subject ? ((_vm.subject.name) + " | ") : '')+_vm._s(_vm.subject.room ? _vm.subject.room.sections : 'Extra Subject')+" ")]):_vm._e(),_c('intract-smart-list',{ref:"attendance-students-smart-list",staticClass:"mt-2",attrs:{"endpoint":_vm.attendanceStudentsEndpoint,"paginated":"","filter-fields":_vm.filterFields},scopedSlots:_vm._u([{key:"custom-filter--dateRange",fn:function(){return [_c('div',[_c('v-dialog',{ref:"viewAttendanceDialog",attrs:{"return-value":_vm.viewAttendanceDate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.viewAttendanceDate=$event},"update:return-value":function($event){_vm.viewAttendanceDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"solo":"","value":_vm.formattedViewAttendanceDateRangeString,"label":"Select a Date Range","prepend-inner-icon":"mdi-calendar","readonly":"","hide-details":"auto","hint":"All attendance records between these two dates will be displayed","persistent-hint":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.viewAttendanceDateModal),callback:function ($$v) {_vm.viewAttendanceDateModal=$$v},expression:"viewAttendanceDateModal"}},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.viewAttendanceDate),callback:function ($$v) {_vm.viewAttendanceDate=$$v},expression:"viewAttendanceDate"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[(
                        _vm.viewAttendanceDate && _vm.viewAttendanceDate.length == 1
                      )?_c('span',{staticClass:"text-caption red--text"},[_vm._v("Please select End Date as well")]):_vm._e()]),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.viewAttendanceDateModal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","disabled":_vm.viewAttendanceDate && _vm.viewAttendanceDate.length == 1,"color":"primary"},on:{"click":function($event){return _vm.$refs.viewAttendanceDialog.save(_vm.viewAttendanceDate)}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)]},proxy:true},{key:"list-item",fn:function(ref){
                      var student = ref.item;
return [_c('v-list-item',{attrs:{"to":{
              name: 'StudentAttendanceDaily',
              params: Object.assign({}, _vm.is_daily ? {} : {subjectId: _vm.$route.params.subjectId},
                {studentId: student.id,
                roomId: student.room.id,
                daily: _vm.is_daily,
                startDate: _vm.viewAttendanceDate[0],
                endDate: _vm.viewAttendanceDate[1]}),
            }}},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":student.image}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(student.full_name))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(student.username)+" ("+_vm._s(student.room.sections)+") ")])],1),_c('v-list-item-action',{attrs:{"set":(_vm.perc = (student.attended_count * 100) / student.total_count)}},[_c('v-progress-circular',{attrs:{"value":_vm.perc,"color":"primary","rotate":"180"}},[_c('p',{staticClass:"pa-0 ma-0 percentage-text primary--text"},[_vm._v(" "+_vm._s(Math.round(_vm.perc))+"% ")])])],1)],1)]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }