<template>
  <v-row justify="center">
    <v-col md="6">
      <div>
        <h3 v-if="subject" class="mx-2 mt-2">
          {{ subject ?  `${subject.name} | ` : '' }}{{ subject.room ? subject.room.sections : 'Extra Subject' }}
        </h3>
        <intract-smart-list
          class="mt-2"
          ref="attendance-students-smart-list"
          :endpoint="attendanceStudentsEndpoint"
          paginated
          :filter-fields="filterFields"
        >
          <template v-slot:custom-filter--dateRange>
            <div>
              <!-- date range filter for viewing attendance -->
              <v-dialog
                ref="viewAttendanceDialog"
                v-model="viewAttendanceDateModal"
                :return-value.sync="viewAttendanceDate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    solo
                    :value="formattedViewAttendanceDateRangeString"
                    label="Select a Date Range"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    hide-details="auto"
                    hint="All attendance records between these two dates will be displayed"
                    persistent-hint
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="viewAttendanceDate" range>
                  <v-row no-gutters>
                    <v-col cols="12" class="text-center">
                      <span
                        v-if="
                          viewAttendanceDate && viewAttendanceDate.length == 1
                        "
                        class="text-caption red--text"
                        >Please select End Date as well</span
                      >
                    </v-col>
                    <v-col class="text-right">
                      <v-btn
                        text
                        color="primary"
                        @click="viewAttendanceDateModal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        :disabled="
                          viewAttendanceDate && viewAttendanceDate.length == 1
                        "
                        color="primary"
                        @click="
                          $refs.viewAttendanceDialog.save(viewAttendanceDate)
                        "
                      >
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-date-picker>
              </v-dialog>
            </div>
          </template>
          <template v-slot:list-item="{ item: student }">
            <v-list-item
              :to="{
                name: 'StudentAttendanceDaily',
                params: {
                  ...is_daily ? {} : {subjectId: $route.params.subjectId},
                  studentId: student.id,
                  roomId: student.room.id,
                  daily: is_daily,
                  startDate: viewAttendanceDate[0],
                  endDate: viewAttendanceDate[1],
                },
              }"
            >
              <v-list-item-avatar>
                <v-img :src="student.image"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ student.full_name }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ student.username }}
                  ({{ student.room.sections }})
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action
                :set="
                  (perc = (student.attended_count * 100) / student.total_count)
                "
              >
                <v-progress-circular :value="perc" color="primary" rotate="180">
                  <p class="pa-0 ma-0 percentage-text primary--text">
                    {{ Math.round(perc) }}%
                  </p>
                </v-progress-circular>
              </v-list-item-action>
            </v-list-item>
          </template>
        </intract-smart-list>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import Mixins from "@utils/mixins";
import IntractSmartList from "@components/generics/IntractSmartList";
import EventBus from "@utils/event_bus";
import moment from "moment";
export default {
  name: "ClassroomAttendance",
  mixins: [Mixins.essentials, Mixins.pullToRefresh],
  components: {
    IntractSmartList,
  },
  props: {
    subject: {
      type: Object,
      default: () => null,
    },
    daily: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      viewAttendanceDate: [],
      viewAttendanceDateModal: false,
      subjectId: this.$route.params.subjectId,
      roomId: this.$route.params.roomId,
    };
  },
  computed: {
    is_daily(){
      let is_daily = (this.daily || this.$route.name == "ClassroomAttendanceDaily")
      return is_daily
    },

    attendanceStudentsEndpoint() {
      var url = null
      if(this.is_daily)
        url = this.endpoints.classroomAttendanceViewSet + "classroom-students" + "/";
      else{
        if (this.roomId)
          url = this.endpoints.attendanceViewSet + "classroom-students" + "/";
        else
          url = this.endpoints.attendanceViewSet + "extra-subject-students" + "/";
      }
      var startDate,
      endDate = null;
      if (moment(this.viewAttendanceDate[0]).isSameOrBefore(moment(this.viewAttendanceDate[1]))) {
        startDate = this.viewAttendanceDate[0];
        endDate = this.viewAttendanceDate[1];
      } else {
        startDate = this.viewAttendanceDate[1];
        endDate = this.viewAttendanceDate[0];
      }
      return this.Helper.addUrlParams(url, [
        ...this.roomId ? ["room=" + this.roomId] : [],
        ...this.is_daily ?  [] :[
          `subject=${this.subjectId}`,          
        ],
        `start_date=${moment(startDate).format(
          this.is_daily ?  'YYYY-MM-DD' : this.Helper.dateURLFormat
        )}`,
        `end_date=${moment(endDate).format(
          this.is_daily ?  'YYYY-MM-DD' : this.Helper.dateURLFormat
        )}`,
      ]);
    },
    filterFields() {
      return {
        dateRange: {
          model: null,
          items: [],
          label: "Filter by Date",
          // itemValue: "id",
          custom: true,
          // itemText: "name",
          // param: "subject__in",
          //   dynamicParam: true
        },
      };
    },
    formattedViewAttendanceDateRangeString() {
      if (!this.viewAttendanceDate) return null;
      if (this.viewAttendanceDate.length == 0) return "";
      else if (this.viewAttendanceDate.length == 1)
        return moment(this.viewAttendanceDate[0]).format("ll") + " - ";
      else
        return (
          moment(this.viewAttendanceDate[0]).format("ll") +
          " - " +
          moment(this.viewAttendanceDate[1]).format("ll")
        );
    },
  },
  methods: {
    async onRefresh() {
      if (this.refs["attendance-students-smart-list"])
        this.refs["attendance-students-smart-list"].getItems();
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == "Attendance") {
      EventBus.$emit("keep_alive__destroy_view", "ClassroomAttendance");
    }
    next();
  },
  created() {
    this.viewAttendanceDate = [
      String(this.$route.params.startDate),
      String(this.$route.params.endDate),
    ];
  },
};
</script>
<style lang="sass" scoped>
.percentage-text
  font-size: 9px
</style>